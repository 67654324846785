/*global angular: false, $: false */

let am = angular.module('mtz-directives');

am.factory('dialogSvc', ['$rootScope', $rootScope => {
  let svc = {};

  svc.hide = id => {
    $('#' + id).modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  };

  svc.hideMessage = () => {
    delete $rootScope.title;
    delete $rootScope.message;
    svc.hide('global-error');
  };

  /**
   * @param id the id of the HTML element that defines the content
   * @param classes an optional array of CSS class names
   *   to add to that element;
   *   Examples include modal-sm and modal-lg
   *   that are defined by Twitter Bootstrap.
   * @param onDismiss an optional function to be called
   *   when the user dismisses the dialog
   */
  svc.show = (id, classes, onDismiss) => {
    let dialog = $('#' + id);
    if (dialog.length === 0) {
      console.log('dialog.js: no dialog found with id ' + id);
    }

    if (Array.isArray(classes)) {
      classes.forEach(dialog.addClass.bind(dialog));
    }
    // Setting backdrop to static prevents the dialog from closing
    // when the user clicks outside of it.
    dialog.modal({backdrop: 'static', show: true});

    // If not already in a digest cycle, trigger one
    // to refresh dialog with latest content.
    if (!$rootScope.$$phase) $rootScope.$apply();

    if (onDismiss) {
      dialog.on('hidden.bs.modal', onDismiss);
    }
  };

  /**
   * @param title displayed in the dialog header
   * @param message displayed in the dialog body
   * @param classes see show method
   * @param onDismiss see show method
   */
  svc.showError = (title, message, classes, onDismiss) => {
    if (!message) {
      message = 'No detail was provided.';
    } else if (Array.isArray(message)) {
      let msgText = '<ul>';

      message.forEach(msg => msgText = msgText + '<li>' + msg.message + '</li>');
      message = msgText + '</ul>';
    } else if (typeof message === 'object') {
      if (message.status === 404) {
        message = message.config.url + ' not found';
      } else {
        message = message.statusText ?
          message.statusText : JSON.stringify(message);
      }
    }

    svc.showMessage(title, message, classes, onDismiss);
  };

  /**
   * This assumes that HTML for a Twitter Bootstrap modal
   * with the id "global-error" exists in the app.
   * For eP3, this is in main/index-in.html.
   * @param title displayed in the dialog header
   * @param message displayed in the dialog body
   * @param classes see show method
   * @param onDismiss see show method
   */
  svc.showMessage = (title, message, classes, onDismiss) => {
    $rootScope.title = title;
    $rootScope.message = message;
    svc.show('global-error', classes, onDismiss);
  };

  return svc;
}]);

/**
 * Example usage:
 * <div id="my-dialog" mtz-dialog
 *   heading="Make a Move"
 *   btn-map="btnMap"
 *   data="data">
 * </div>
 *
 * btnMap is an object on the scope
 * whose keys are the text for buttons in the footer and
 * whose values are functions to invoke when the buttons are pressed.
 * Omit btn-map if no buttons are needed at the bottom of the dialog.
 * In that case there will be no footer area.
 *
 * data is an object on the scope that can be used to
 * make data available to the trancluded HTML
 * and make result data available to the code
 * that causes the dialog to be displayed.
 * It can also hold functions to be invoked
 * when specific buttons are pressed.
 *
 * When width is used, an id MUST be given on the element
 * where mtz-dialog is applied.
 *
 * To display the dialog,
 * dialogSvc.show('my-dialog');
 */
am.directive('mtzDialog', () => {
  // JSHint complains if this form is used: ({ ... })
  return {
    restrict: 'AE',
    scope: {
      btnMap: '=',
      busyRef: '=',
      heading: '@',
      data: '=',
      width: '='
    },
    replace: true,
    link(scope, element, attrs) {
      scope.$watch('width', () => {
        if (attrs.id && scope.width) {
          let jq = $('#' + attrs.id + ' .modal-dialog');
          jq.css('width', scope.width + 'px');
        }
      });
    },
    template: `<div class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" ng-if="heading">
          <button class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">{{heading}}</h4>
        </div>
        <div class="modal-body" ng-transclude></div>
        <div class="modal-footer" ng-show="btnMap">
          <img src="../../../images/spinners/spinner.gif" ng-show="busyRef">
          <!-- Each button could automatically dismiss the dialog
               by adding data-dismiss="modal", but we don't always
               want every button to do that. -->
          <button type="button" class="btn btn-default"
            ng-repeat="(text, fn) in btnMap" ng-click="fn()">
            {{text}}
          </button>
        </div> <!-- modal-footer -->
      </div> <!-- modal-content -->
    </div> <!-- modal-dialog -->
  </div>`,
    transclude: true
  };
});
